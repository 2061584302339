var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.handleEdit(-100, null)
                }
              }
            },
            [_vm._v("添加分类")]
          )
        ],
        1
      ),
      _c("el-tree", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        attrs: {
          data: _vm.data,
          props: { children: "children", label: "name" },
          accordion: "",
          "node-key": "id",
          "default-expand-all": "",
          "expand-on-click-node": false
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var node = ref.node
              var data = ref.data
              return _c("span", { staticClass: "custom-tree-node" }, [
                _c("span", [_vm._v(_vm._s(data.name))]),
                _c(
                  "span",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function() {
                            return _vm.handleEdit(node, data)
                          }
                        }
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function() {
                            return _vm.handleDelete(node, data)
                          }
                        }
                      },
                      [_vm._v(" 删除 ")]
                    )
                  ],
                  1
                )
              ])
            }
          }
        ])
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "分类增加/修改",
            visible: _vm.dialogNewVisible,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogNewVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "newForm",
              attrs: {
                model: _vm.newForm,
                rules: _vm.rules,
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分类名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.newForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "name", $$v)
                      },
                      expression: "newForm.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上级分类", prop: "parent_id" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      options: _vm.data,
                      props: {
                        checkStrictly: true,
                        emitPath: false,
                        value: "id",
                        label: "name"
                      },
                      clearable: ""
                    },
                    model: {
                      value: _vm.newForm.parent_id,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "parent_id", $$v)
                      },
                      expression: "newForm.parent_id"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitting },
                  on: {
                    click: function($event) {
                      return _vm.handleSave("newForm")
                    }
                  }
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogNewVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }