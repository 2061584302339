import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { deleteApiGroup, editApiGroup, getApiGroupList } from '@/api/apiGroup';
export default {
  name: "ManagerApiGroups",
  data: function data() {
    return {
      data: [],
      loading: false,
      formLabelWidth: '120px',
      dialogNewVisible: false,
      submitting: false,
      newForm: {
        id: 0,
        name: '',
        parent_id: 0
      },
      rules: {
        name: [{
          required: true,
          message: '分类名称 必填'
        }]
      }
    };
  },
  created: function created() {
    this.requestData();
  },
  methods: {
    requestData: function requestData() {
      var _this = this;

      this.loading = true;
      getApiGroupList({
        is_only_group: true
      }).then(function (res) {
        _this.loading = false;
        _this.data = res.data.data;
      }).catch(function (e) {
        _this.$message.error(e.message);
      });
    },
    handleDelete: function handleDelete(node, data) {
      var _this2 = this;

      this.$confirm('您确定要删除这项数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deleteApiGroup({
          id: data.id
        }).then(function () {
          _this2.$message.success('删除成功');

          var parent = node.parent;
          var children = parent.data.children || parent.data;
          var index = children.findIndex(function (d) {
            return d.id === data.id;
          });
          children.splice(index, 1);
        }).catch(function (e) {
          _this2.$message.error(e.message);
        });
      }).catch(function () {
        _this2.$message.info('已取消删除');
      });
    },
    handleEdit: function handleEdit(node, data) {
      var _this3 = this;

      if (data) {
        Object.keys(data).forEach(function (key) {
          if (Object.prototype.hasOwnProperty.call(_this3.newForm, key) && data[key] != null) _this3.newForm[key] = data[key];
        });
      } else {
        this.newForm = {
          id: 0,
          name: '',
          parent_id: 0
        };
      }

      this.dialogNewVisible = true;
    },
    handleSave: function handleSave(formName) {
      var _this4 = this;

      this.submitting = true;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var form = _objectSpread({}, _this4.newForm);

          editApiGroup(form).then(function () {
            _this4.submitting = false;

            _this4.$message.success('编辑成功');

            _this4.dialogNewVisible = false;

            _this4.requestData();
          }).catch(function (e) {
            _this4.submitting = false;

            _this4.$message.error(e.message);
          });
        } else {
          _this4.submitting = false;
        }
      });
    }
  }
};